:root {
	--red-color: #eb4d4d;
	--light-blue-color: #7FAFE1;
	--pink-color: #E19991;
	--beige-color: #F3EED9;
}

html {
	height: 100%;
}

body {
	min-height: 100%;
	margin: 0;
	font-family: 'Comfortaa', cursive !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.rrt-title {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif !important;
	font-weight: normal !important;
}

.rrt-middle-container {
	margin: 0px 10px !important;
	width: 85% !important;
}

.rrt-right-container {
	width: 15% !important;
}

.close-toastr {
	margin-right: 5px !important;
	color: white !important;
	font-size: 15px !important;
}

.rw-multiselect-taglist {
	margin-top: auto !important;
}

.rrt-left-container {
	display: none;
}

.toastr {
	min-height: auto !important;
}

.audioPreviewName {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.loader {
	border: 8px solid #f8f8f8;
	border-top: 8px solid var(--red-color);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

.small-loader {
	border: 4px solid #f8f8f8;
	border-top: 4px solid var(--red-color);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.card {
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

form label.required:after {
	color: red;
	content: " *";
}

.fileContainer {
	width: 100%;
}

.fileContainer input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fileContainer p {
	font-size: 12px;
	margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
	max-width: 300px;
	font-size: 12px;
	color: red;
	text-align: left;
}

.fileContainer .chooseFileButton:hover {
	background: #545972;
}

.fileContainer .uploadFilesButton {
	padding: 5px 43px;
	background: transparent;
	border-radius: 30px;
	color: #3f4257;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
	background: #3f4257;
	color: #fff;
}

.fileContainer .uploadIcon {
	width: 50px;
	height: 50px;
}

.fileContainer .uploadPicturesWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.fileContainer .uploadPictureContainer {
	width: 25%;
	margin: 2% 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 100%;
}

.fileContainer .deleteImage {
	position: absolute;
	top: -9px;
	right: -9px;
	margin: 0 !important;
	border-radius: 50%;
	background-color: white;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;
	font-size: 18px;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
}

.imagePreview .deleteImage {
	position: absolute;
	top: -9px;
	left: -9px;
	margin: 0 !important;
	border-radius: 50%;
	background-color: white;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;
	font-size: 18px;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
}

.flipMove {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}