body {
    background-color: #f8f8f8;
}

.driver-loading {
    height: 100vh;
    text-align: center;
}

.loading-container {
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
}