.info {
    color: var(--pink-color);
    font-size: 0.9rem;
}

.rw-multiselect-taglist {
    margin-top: auto;
    margin-bottom: 0.4rem;
}

.rw-multiselect-tag {
    margin-top: 0;
}