

.business-search {
  position: relative;
}
.search-input-container {
  position: relative;
}

.autocomplete-container {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 999;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}

.manual {
  color: var(--light-blue-color)
}

.manual:hover {
  font-weight: 600;
  cursor: pointer;
}

.manual-info {
  font-size: 0.8rem;
  color: #111111;
}