.link {
    font-size: 1.4rem !important;
    text-align: center;
}

.link:hover {
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.link-container {
    text-align: center;
}

.link-container.container2 {
    margin-top: 0.25rem;
}

@media screen and (min-width: 768px) {
    .link-container.container1 {
        text-align: left;
    }

    .link-container.container2 {
        text-align: right;
        margin-top: 0;
    }
}