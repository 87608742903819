body {
    background-color: white !important;
}

.my-container {
    width: 100%;
    height: 100%;
    margin: 1rem auto;
    background-color: white;
}

.my-container h3 {
    font-weight: 600;
}


@media screen and (min-width: 768px) {
    .line-container {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 10px;
        overflow: hidden;
    }
    .line {
        background-color: var(--red-color);
        height: 10px;
    }

    .my-container {
        width: 500px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    body {
        background-color: #f8f8f8;
    }
}

@media screen and (min-width: 992px) {
    .my-container {
        width: 600px;
    }
}